export enum Api {
  // @ts-ignore
  CallTrackingManagementService = process.env.VUE_APP_CALL_TRACKING_MANAGEMENT_API_URI,
  // @ts-ignore
  CaProviderVbService = process.env.VUE_APP_CA_PROVIDER_VB_SERVICE,
  // @ts-ignore
  CaService = process.env.VUE_APP_CA_SERVICE_API_URI,
  // @ts-ignore
  CaServiceV2 = process.env.VUE_APP_CA_SERVICE_API_V2_URI,
  // @ts-ignore
  CaTestingService = process.env.VUE_APP_CA_TESTING_API_URI,
  // @ts-ignore
  ChannelService = process.env.VUE_APP_CHANNEL_CONFIG_URI,
  // @ts-ignore
  CustomerScoreService = process.env.VUE_APP_MFE_CUSTOMER_SCORE_API_URI,
  // @ts-ignore
  EmailNotificationService = process.env.VUE_APP_EMAIL_NOTIFICATION_SERVICE,
  // @ts-ignore
  GoogleAdsService = process.env.VUE_APP_GOOGLE_ADS_SERVICE,
  // @ts-ignore
  Hub = process.env.VUE_APP_HUB_API_URI,
  // @ts-ignore
  IdentityService = process.env.VUE_APP_IDENTITY_SERVICE_API_URI,
  // @ts-ignore
  IM = process.env.VUE_APP_INTELLIGENT_MATCH_API_URI,
  // @ts-ignore
  IntegrationsService = process.env.VUE_APP_INTEGRATIONS_SERVICE,
  // @ts-ignore
  LivePersonConversationService = process.env.VUE_APP_LIVE_PERSON_CONVERSATION_API_URI,
  // @ts-ignore
  MicrosoftAdsService = process.env.VUE_APP_MICROSOFT_ADS_SERVICE,
  // @ts-ignore
  ReportsEventsApi = process.env.VUE_APP_REPORTS_EVENTS_API_URI,
  // @ts-ignore
  TrackingService = process.env.VUE_APP_TRACKING_SERVICE_API_URI,
  // @ts-ignore
  WebhooksService = process.env.VUE_APP_WEBHOOKS_SERVICE,
}

// Generic form API response, prevents the need for strongly typed responses
export type JsonData = { [k: string]: string | number | boolean | string[] | string[][] | number[] | boolean[] | JsonData | JsonData[] | null | undefined }

export enum RequestMethod { GET = 'GET', POST = 'POST', PUT = 'PUT', PATCH = 'PATCH', DELETE = 'DELETE' }

export enum InfinityStatus { Ok = 200, Deleted = 410 }
